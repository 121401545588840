import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      loginRequired: true,
      title: 'Swiss Side WT'
    },
    component: HomeView
  },
  {
    path: '/sessions',
    name: 'sessions',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - Sessions'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/SessionsView.vue')
    }
  },
  {
    path: '/session/:id',
    name: 'session',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - Session'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/SessionView.vue')
    }
  },
  {
    path: '/session/:id/gst',
    name: 'gst',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - GST Matching'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/SessionGstView.vue')
    }
  },
  {
    path: '/session/:id/gst/run_list',
    name: 'gstRunList',
    meta: {
      title: 'Swiss Side WT - Run List'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/SessionGstRunListView.vue')
    }
  },
  {
    path: '/session/:id/report/:reportId?',
    name: 'report',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - Report'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/ReportView.vue')
    }
  },
  {
    path: '/report_templates/:id?',
    name: 'reportTemplates',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - Report Templates'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/ReportTemplatesView.vue')
    }
  },
  {
    path: '/report_template_panels/:id?',
    name: 'reportTemplatePanels',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - Report Template Panels'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/ReportTemplatePanelsView.vue')
    }
  },
  {
    path: '/report_weightings/:id?',
    name: 'reportWeightings',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - Report Weightings'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/ReportWeightingsView.vue')
    }
  },
  {
    path: '/maps/:id?',
    name: 'maps',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - Maps'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/MapCreatorView.vue')
    }
  },
  {
    path: '/run_lists/:id?',
    name: 'runLists',
    meta: {
      loginRequired: true,
      claimsRequired: ['wind-tunnel-user'],
      title: 'Swiss Side WT - Run Lists'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/RunListsView.vue')
    }
  },
  {
    path: '/live/:id?',
    name: 'live',
    meta: {
      title: 'Swiss Side WT - Live'
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/LiveView.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Swiss Side WT - Login'
    },
    component: function () {
      return import('../views/LoginView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 60
      }
    } else {
      return { top: 0 }
    }
  }
})

const routerLogging = false
function logWrap(message) {
  if (routerLogging) {
    console.log(message)
  }
}

router.beforeEach((to, from, next) => {

  logWrap('---------')
  logWrap(from.name + ' -> ' + to.name)

  var authenticated = false
  var authorized = false

  var user = store.state.user

  // Check if authenticated
  if (to.matched.some((record) => record.meta.loginRequired)) {
    logWrap('Login is required for this route')
    if (user !== null) {
      authenticated = true
    }
  } else {
    logWrap('Login is NOT required for this route')
    authenticated = true
  }

  // Check claims
  var claimsRequired = to.matched.filter(route => route.meta?.claimsRequired).map(route => route.meta?.claimsRequired).flat()
  if (claimsRequired.length) {
    logWrap('Claims required for this route')
    logWrap(claimsRequired)
    if (authenticated) { // Only check if authenticated - otherwise error
      if (claimsRequired.every((claim) => Object.keys(store.state.userClaims).includes(claim))) {
        authorized = true
      }
    }
  } else {
    logWrap('NO claim required for this route')
    authorized = true
  }
  
  logWrap('Check:')
  logWrap('Authenticated: ' + authenticated)
  logWrap('Authorized: ' + authorized)

  if (authenticated && authorized) {
    next()
  } else {
    next('/login')
  }

})

export default router
