import { getApp } from 'firebase/app'
import { getFirestore, collection, doc, onSnapshot, getDocs, getDoc, query, where } from 'firebase/firestore'

function getNested(obj, nesting) {
  var currentNesting = obj
  nesting.forEach(key => {
    if (!(key in currentNesting)) {
      currentNesting[key] = {}
    }
    currentNesting = currentNesting[key]
  })
  return currentNesting
}

export default {
  fetchByName(commit, state, name) {
    let db = getFirestore(getApp())
    const setterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'ById'
    getDocs(collection(db, name)).then(snapshot => {
      snapshot.forEach(doc => {
        commit(setterName, {id: doc.id, data: doc.data()})
      })
    })
  },
  fetchByNameAndId(commit, state, name, id) {
    let db = getFirestore(getApp())
    const setterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'ById'
    getDoc(doc(db, name, id)).then(docSnapshot => {
      commit(setterName, {id: id, data: docSnapshot.data()})
    })
  },
  fetchByNameNested(commit, state, nestedName, setNesting, name) {
    let db = getFirestore(getApp())
    const setterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'NestedById'
    console.log(setterName)
    getDocs(collection(db, ...nestedName)).then(snapshot => {
      snapshot.forEach(doc => {
        commit(setterName, {id: doc.id, nesting: setNesting, data: doc.data()})
      })
    }).catch(error => {
      console.log(error)
    })
  },
  fetchByNameNestedAndId(commit, state, nestedName, setNesting, name, id) {
    let db = getFirestore(getApp())
    const setterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'NestedById'
    console.log(setterName)
    getDoc(doc(db, name, id)).then(docSnapshot => {
      commit(setterName, {id: id, nesting: setNesting, data: docSnapshot.data()})
    }).catch(error => {
      console.log(error)
    })
  },
  setNameById(state, data, name) {
    if (data?.changeType === 'removed') {
      delete state[name][data.id]
    } else {
      state[name][data.id] = data.data
    }
  },
  setNameNestedById(state, data, name) {
    if (data?.changeType === 'removed') {
      delete getNested(state[name], data.nesting)[data.id]
    } else {
      getNested(state[name], data.nesting)[data.id] = data.data
    }
  },
  subscribeByName(commit, state, name) {
    let db = getFirestore(getApp())
    const setterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'ById'
    const subscriptionName = name + 'Subscription'
    const subscriptionSetterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'Subscription'
    if (state[subscriptionName] !== null) {
      //console.log('Had ' + name + ' subscription, unsubscribing and adding again')
      state[subscriptionName]()
    }
    const unSubscribeFunction = onSnapshot(collection(db, name), (snapshot) => {
      //console.log('Changes in ' + name)
      snapshot.docChanges().forEach((change) => {
        //console.log(name + ' document ' + change.doc.id + ' changed')
        commit(setterName, {id: change.doc.id, changeType: change?.type, data: change.doc.data()})
      })
    })
    commit(subscriptionSetterName, unSubscribeFunction)
  },
  subscribeByNameNested (commit, state, nestedName, setNesting, name) {
    let db = getFirestore(getApp())
    const setterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'NestedById'
    const subscriptionName = name + 'Subscriptions'
    const subscriptionSetterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'NestedSubscription'
    if (getNested(state[subscriptionName], setNesting.slice(0, setNesting.length-1))?.[setNesting.length-1]) {
      //console.log(['Had ', name, ': ', setNesting, ' nested subscription, unsubscribing and adding again'].join(''))
      getNested(state[subscriptionName], setNesting.slice(0, setNesting.length-1))?.[setNesting.length-1]()
    }
    const unSubscribeFunction = onSnapshot(collection(db, ...nestedName), (snapshot) => {
      console.log('Changes in ' + name)
      snapshot.docChanges().forEach((change) => {
        //console.log(name + ' document ' + change.doc.id + ' changed')
        commit(setterName, {id: change.doc.id, changeType: change?.type, nesting: setNesting, data: change.doc.data()})
      })
    })
    commit(subscriptionSetterName, {nesting: setNesting, sub: unSubscribeFunction})
  },
  subscribeByNameNestedFiltered (commit, state, nestedName, setNesting, filters, name) {
    let db = getFirestore(getApp())
    const setterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'NestedById'
    const subscriptionName = name + 'FilteredSubscriptions'
    const subscriptionSetterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'NestedSubscription'
    if (getNested(state[subscriptionName], setNesting.slice(0, setNesting.length-1))?.[setNesting.length-1]) {
      //console.log(['Had ', name, ': ', setNesting, ' nested subscription, unsubscribing and adding again'].join(''))
      getNested(state[subscriptionName], setNesting.slice(0, setNesting.length-1))?.[setNesting.length-1]()
    }
    var filteredQuery = query(collection(db, ...nestedName), ...filters.map(filter => where(...filter)))
    const unSubscribeFunction = onSnapshot(filteredQuery, (snapshot) => {
      //console.log('Changes in ' + name)
      snapshot.docChanges().forEach((change) => {
        //console.log(name + ' document ' + change.doc.id + ' changed')
        commit(setterName, {id: change.doc.id, changeType: change?.type, nesting: setNesting, data: change.doc.data()})
      })
    })
    commit(subscriptionSetterName, {nesting: setNesting, sub: unSubscribeFunction})
  },
  unsubscribeByName (commit, state, name) {
    const subscriptionName = name + 'Subscription'
    const subscriptionSetterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'Subscription'
    if (state[subscriptionName] !== null) {
      //console.log('Had ' + name + ' subscription, unsubscribing')
      state[subscriptionName]()
      commit(subscriptionSetterName, null)
    } else {
      console.log('Did not have ' + name + ' susbscription no need to unsub')
    }
  },
  unsubscribeByNameNested (commit, state, setNesting, name) {
    let db = getFirestore(getApp())
    const subscriptionName = name + 'Subscriptions'
    const subscriptionSetterName = 'set' + name.charAt(0).toUpperCase() + name.slice(1) + 'NestedSubscription'
    if (getNested(state[subscriptionName], setNesting.slice(0, setNesting.length-1))?.[setNesting[setNesting.length-1]]) {
      //console.log(['Had ', name, ': ', setNesting, ' nested subscription, unsubscribing'].join(''))
      getNested(state[subscriptionName], setNesting.slice(0, setNesting.length-1))?.[setNesting[setNesting.length-1]]()
      commit(subscriptionSetterName, {nesting: setNesting, sub: null})
    } else {
      console.log('Did not have ' + name + ': ' + setNesting + ' susbscription no need to unsub')
    }
  },
  setNameSubscription (state, data, name) {
    state[name + 'Subscription'] = data
  },
  setNameNestedSubscription (state, data, name) {
    getNested(state[name + 'Subscriptions'], data.nesting.slice(0, data.nesting.length-1))[data.nesting[data.nesting.length-1]] = data.sub
  }
}
