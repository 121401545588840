export const live = {
    namespaced: true,
    state () {
        return {
            // currently empty state
        }
    },
    actions: {
    },
    mutations: {
    },
    getters: {
    }
  }
  