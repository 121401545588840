import storeHelpers from '../helpers'

export const runLists = {
  namespaced: true,
  state () {
    return {
      runLists: {},
      runListsSubscription: null,
      runListRuns: {},
      runListRunsSubscriptions: {}
    }
  },
  mutations: {
    setRunListsById (state, data) {
      storeHelpers.setNameById(state, data, 'runLists')
    },
    setRunListsSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'runLists')
    },
    setRunListRunsNestedById (state, data) {
      storeHelpers.setNameNestedById(state, data, 'runListRuns')
    },
    setRunListRunsNestedSubscription (state, data) {
      storeHelpers.setNameNestedSubscription(state, data, 'runListRuns')
    }
  },
  actions: {
    subscribeRunLists ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'runLists')
    },  
    unsubscribeRunLists ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'runLists')
    },
    subscribeRunListRuns ({ commit, state }, runListId) {
      storeHelpers.subscribeByNameNested (commit, state, ['runLists', runListId, 'runs'], [runListId], 'runListRuns')
    },
    unsubscribeRunListRuns ({ commit, state }, runListId) {
      storeHelpers.unsubscribeByNameNested(commit, state, [runListId], 'runListRuns')
    }
  },
  getters: {
  }
}
