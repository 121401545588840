import storeHelpers from '../helpers'

export const images = {
  namespaced: true,
  state () {
    return {
      imagesBySession: {},
      imagesBySessionSubscriptions: {},
      imagesByRun: {},
      imagesByRunSubscriptions: {}
    }
  },
  mutations: {
    setImagesBySessionNestedById (state, data) {
      storeHelpers.setNameNestedById(state, data, 'imagesBySession')
    },
    setImagesByRunNestedById (state, data) {
      storeHelpers.setNameNestedById(state, data, 'imagesByRun')
    },
    setImagesBySessionNestedSubscription (state, data) {
      storeHelpers.setNameNestedSubscription(state, data, 'imagesBySession')
    },
    setImagesByRunNestedSubscription (state, data) {
      storeHelpers.setNameNestedSubscription(state, data, 'imagesByRun')
    }
  },
  actions: {
    subscribeImagesBySession ({ commit, state }, sessionId) {
      storeHelpers.subscribeByNameNestedFiltered(commit, state, ['images'], [sessionId], [['session_id', '==', sessionId]], 'imagesBySession')
    },
    subscribeImagesByRun ({ commit, state }, runId) {
      storeHelpers.subscribeByNameNestedFiltered(commit, state, ['images'], [runId], [['run_id', '==', runId]], 'imagesByRun')
    },
    unsubscribeImagesBySession ({ commit, state }, sessionId) {
      storeHelpers.unsubscribeByNameNested(commit, state, [sessionId], 'imagesBySession')
    },
    unsubscribeImagesByRun ({ commit, state }, runId) {
      storeHelpers.unsubscribeByNameNested(commit, state, [runId], 'imagesByRun')
    }
  },
  getters: {
  }
}
