<template>
  <NavBarBootstrap>
    <template v-slot:menu>
      <NavBarBootstrapMenuLink v-if="userClaims.includes('wind-tunnel-user')" :to="'/sessions'">Sessions</NavBarBootstrapMenuLink>
      <NavBarBootstrapMenuLink v-if="userClaims.includes('wind-tunnel-user')" :to="'/maps'">Maps</NavBarBootstrapMenuLink>
      <NavBarBootstrapMenuLink v-if="userClaims.includes('wind-tunnel-user')" :to="'/run_lists'">Run lists</NavBarBootstrapMenuLink>
      <template v-if="showLiveView">
        <NavBarBootstrapMenuLink v-if="showGstView" :to="'/live/' + gstSessionNr">Live</NavBarBootstrapMenuLink>
        <NavBarBootstrapMenuLink v-else :to="'/live'">Live</NavBarBootstrapMenuLink>
      </template>
    </template>
    <template v-slot:profile>
      <div class="dropdown" v-if="$store.state?.user" >
        <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <ProfilePhoto imagewidth="40" :user="$store.state?.user" />
        </div>
        <ul class="dropdown-menu dropdown-menu-end">
          <li class="mx-3">{{ $store.state?.user.displayName }}<br>
            <small>id:&nbsp;{{ $store.state?.user.uid }}</small>
          </li>
          <li><hr class="dropdown-divider"></li>
          <li><button class="dropdown-item" @click="logout()">Logout</button></li>
        </ul>
      </div>
      <div v-else>
        <div class="dropdown m-2">
          <button class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-door-open"></i> Login </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><button class="btn btn-primary dropdown-item" @click="signInWithGoogle()"><i class="bi bi-google"></i> with Google </button></li>
          </ul>
        </div>
      </div>
    </template>
  </NavBarBootstrap>
</template>

<script>

import { getAuth, signOut, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

import NavBarBootstrap from '@/components/shared/NavBarBootstrap.vue'
import NavBarBootstrapMenuLink from '@/components/shared/NavBarBootstrapMenuLink.vue'
import ProfilePhoto from '@/components/shared/ProfilePhoto.vue'
import { v3 as uuidv3 } from 'uuid'

export default {
  name: 'TopBar',
  components: {
    NavBarBootstrap,
    NavBarBootstrapMenuLink,
    ProfilePhoto
  },
  computed: {
    userClaims () {
      if (this.$store.state.userClaims) {
        return Object.keys(this.$store.state.userClaims)
      } else {
        return []
      }
    },
    showLiveView () {
      return this.$showLiveView
    },
    showGstView () {
      return this.$showGstView
    },
    gstSessionNr () {
      return uuidv3((new Date ()).toISOString().split('T')[0], '6ba7b810-9dad-11d1-80b4-00c04fd430c8')
    }
  },
  methods: {
    logout () {
      signOut(getAuth())
        .then(() => {
          this.$store.dispatch('setUser', null)
          this.$router.push({name: 'login'})
        })
        .catch((error) => {
          console.log(error)
        })
    },
    signInWithGoogle () {
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log(result)
          this.$store.dispatch('setUser', result.user)
        }).catch((error) => {
          this.errorMessage = error.message
        })
    }
  }
}
</script>
