import storeHelpers from '../helpers'

export const runs = {
  namespaced: true,
  state () {
    return {
      runsBySession: {},
      runsBySessionSubscriptions: {}
    }
  },
  mutations: {
    setRunsBySessionNestedById (state, data) {
      storeHelpers.setNameNestedById(state, data, 'runsBySession')
    },
    setRunsBySessionNestedSubscription (state, data) {
      storeHelpers.setNameNestedSubscription(state, data, 'runsBySession')
    }
  },
  actions: {
    subscribeRunsBySession ({ commit, state }, sessionId) {
      if (sessionId === null) {
        storeHelpers.subscribeByNameNestedFiltered(commit, state, ['runs'], [sessionId], [['session', '==', sessionId]], 'runsBySession')
      } else {
        storeHelpers.subscribeByNameNestedFiltered(commit, state, ['runs'], [sessionId], [['sessions', 'array-contains', sessionId]], 'runsBySession')
      }
    },
    unsubscribeRunsBySession ({ commit, state }, sessionId) {
      storeHelpers.unsubscribeByNameNested(commit, state, [sessionId], 'runsBySession')
    }
  },
  getters: {
  }
}
