import storeHelpers from '../helpers'

export const sessions = {
  namespaced: true,
  state () {
    return {
      sessions: {},
      sessionsSubscription: null,
      sessionGstRunListRuns: {},
      sessionGstRunListRunsSubscriptions: {}
    }
  },
  mutations: {
    setSessionsById (state, data) {
      storeHelpers.setNameById(state, data, 'sessions')
    },
    setSessionsSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'sessions')
    },
    setSessionGstRunListRunsNestedById (state, data) {
      storeHelpers.setNameNestedById(state, data, 'sessionGstRunListRuns')
    },
    setSessionGstRunListRunsNestedSubscription (state, data) {
      storeHelpers.setNameNestedSubscription(state, data, 'sessionGstRunListRuns')
    }
  },
  actions: {
    subscribeSessions ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'sessions')
    },  
    unsubscribeSessions ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'sessions')
    },
    subscribeSessionGstRunListRuns ({ commit, state }, sessionId) {
      storeHelpers.subscribeByNameNested (commit, state, ['sessions', sessionId, 'gst_run_list_runs'], [sessionId], 'sessionGstRunListRuns')
    },
    unsubscribeSessionGstRunListRuns ({ commit, state }, sessionId) {
      storeHelpers.unsubscribeByNameNested(commit, state, [sessionId], 'sessionGstRunListRuns')
    }
  },
  getters: {
    sessionsGeneralType (state) {
      const allGeneralTypes = Object.entries(state.sessions).map(([sessionId, sessionData]) => {
        return {
          id: sessionId,
          general_type: sessionData?.general_type
        }
      }).map(({general_type}) => (general_type)).filter(n => n)
      return [...new Set(allGeneralTypes)].sort()
    },
    sessionsSpecificType (state) {
      const allSpecificTypes = Object.entries(state.sessions).map(([sessionId, sessionData]) => {
        return {
          id: sessionId,
          specific_type: sessionData?.specific_type
        }
      }).map(({specific_type}) => (specific_type)).filter(n => n)
      return [...new Set(allSpecificTypes)].sort()
    },
    sessionsProject (state) {
      const allProjects = Object.entries(state.sessions).map(([sessionId, sessionData]) => {
        return {
          id: sessionId,
          project: sessionData?.project
        }
      }).map(({project}) => (project)).filter(n => n)
      return [...new Set(allProjects)].sort()
    }
  }
}
