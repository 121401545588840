<template>
  <div style="padding-top: 70px;">
    <TopBar/>
    <div class="view">
      <router-view/>
    </div>
    <notifications position="bottom right" :duration="-1" />
  </div>
</template>

<script>

import TopBar from '@/components/TopBar.vue'

export default {
  components: {
    TopBar
  }
}
</script>

<style scoped>
.view {
  width: 100%;
  padding-bottom: 0.5rem;
}
</style>

<style>
:root {
  --sws-logo-red: #CE0E2D;
  --sws-primary-color: #2874A6;
  --sws-primary-color-darker: #21618C;
  --sws-secondary-color: #d3d4d5;
  --sws-secondary-color-darker: #c6c7c8;
  --sws-accent-color-lightest: #EBF5FB;
  --sws-accent-color: #D6EAF8;
  --sws-accent-color-strong: #3498DB;
  --bs-dropdown-link-active-bg: var(--sws-primary-color) !important;
}

.btn-primary {
  --bs-btn-bg: var(--sws-primary-color) !important;
  --bs-btn-border-color: var(--sws-primary-color) !important;
  --bs-btn-hover-bg: var(--sws-primary-color-darker) !important;
  --bs-btn-hover-border-color: var(--sws-primary-color-darker) !important;
  --bs-btn-active-bg: var(--sws-primary-color) !important;
  --bs-btn-active-border-color: var(--sws-primary-color) !important;
  --bs-btn-disabled-bg: var(--sws-primary-color) !important;
  --bs-btn-disabled-border-color: var(--sws-primary-color) !important;
}

.btn-outline-primary {
  --bs-btn-color: var(--sws-primary-color) !important;
  --bs-btn-border-color: var(--sws-primary-color) !important;
  --bs-btn-hover-bg: var(--sws-primary-color) !important;
  --bs-btn-hover-border-color: var(--sws-primary-color) !important;
  --bs-btn-active-bg: var(--sws-primary-color) !important;
  --bs-btn-active-border-color: var(--sws-primary-color) !important;
}

.btn-secondary {
  --bs-btn-bg: var(--sws-secondary-color) !important;
  --bs-btn-border-color: var(--sws-secondary-color) !important;
  --bs-btn-hover-bg: var(--sws-secondary-color-darker) !important;
  --bs-btn-hover-border-color: var(--sws-secondary-color-darker) !important;
  --bs-btn-active-bg: var(--sws-secondary-color) !important;
  --bs-btn-active-border-color: var(--sws-secondary-color) !important;
  --bs-btn-color: #000 !important;
  --bs-btn-hover-color: #000 !important;
  --bs-btn-active-color: #000 !important;
}

.mylink {
  color: var(--sws-primary-color) !important;
  text-decoration: none;
  cursor: pointer;
}

.mylink:hover {
  color: var(--sws-primary-color-darker) !important;
}
</style>