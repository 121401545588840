<template>
  <div class="d-flex flex-column align-items-center m-2">
    <h1>Swiss Side Wind Tunnel Platform</h1>
    <p>This should be the only thing you see if you're not authorized...</p>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  mounted () {
    document.title = this.$route.meta.title
  }
}
</script>
