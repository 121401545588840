

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { initializeApp } from 'firebase/app'
import { getAuth, onIdTokenChanged } from 'firebase/auth'
import { getFirestore, collection, onSnapshot, query, where, doc, setDoc } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from 'firebase/storage'

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDzerNqnkX2J3gRwZaiTWqxeCoJDt7L-Xk",
    authDomain: "sws-wind-tunnel.firebaseapp.com",
    projectId: "sws-wind-tunnel",
    storageBucket: "sws-wind-tunnel.appspot.com",
    messagingSenderId: "383279508185",
    appId: "1:383279508185:web:d90ca1ab819d23dd066cc8"
}
const firebaseApp = initializeApp(firebaseConfig);

// Notifications
import Notifications from '@kyvg/vue3-notification'

// Vuetify
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
  defaults: {
    VSelect: {
      variant: 'outlined',
      density: 'compact',
      hideDetails: 'true',
    },
    VAutocomplete: {
      variant: 'outlined',
      density: 'compact',
      hideDetails: 'true',
    },
    VField: {
      baseColor: '#81909d',
      style: 'border-radius: 0.375rem;',
    },
    VLabel: {
      style: 'color: black; opacity: 1;',
    },
    VBtn: {
      elevation: '0',
      size: 'small',
      rounded: '3',
    },
    VList: {
      density: 'compact',
    },
  },
})

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import './assets/main.css'

// Initialize app only when the user auth state is loaded to prevent routing without user
let app
let unsubscribeFromNotifications = () => {}
onIdTokenChanged(getAuth(), user => {
  // Load in the user - make access token and claims easily accessible 
  store.dispatch('setUser', user)
  if (!app) {
    app = createApp(App)
    app.config.globalProperties.$apiUrl = process.env.VUE_APP_API_URL
    app.config.globalProperties.$mqttHost = process.env.VUE_APP_MQTT_HOST
    app.config.globalProperties.$liveDBHost = process.env.VUE_APP_LIVE_DB_HOST
    app.config.globalProperties.$showLiveView = process.env.VUE_APP_SHOW_LIVE_VIEW
    app.config.globalProperties.$showGstView = process.env.VUE_APP_SHOW_GST_VIEW
    app.config.globalProperties.$gstApiUrl = process.env.VUE_APP_GST_API_URL
    app.use(store).use(router).use(Notifications).use(vuetify).mount('#app')
  }
  unsubscribeFromNotifications()
  const firestore = getFirestore(firebaseApp)
  const notificationsCollectionRef = collection(firestore, 'notifications')
  const userNotificationsQuery = query(notificationsCollectionRef, where("user", "==", user.uid), where("showed", "==", false))
  unsubscribeFromNotifications = onSnapshot(userNotificationsQuery, (querySnapshot) => {
    querySnapshot.forEach((queryDocSnapshot) => {
      const data = queryDocSnapshot.data()
      let notification = {
        type: data.type,
        title: data.title,
        text: data.text
      }
      const notificationRef = doc(firestore, 'notifications', queryDocSnapshot.id)
      const showNotification = () => {
        app.config.globalProperties.$notify(notification)
        setDoc(notificationRef, { showed: true }, { merge: true })
      }
      if (data.download_blob) {
        const storage = getStorage()
        getDownloadURL(ref(storage, data.download_blob)).then(url => {
          notification.text += " <a href=" + url +">Download</a>" 
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          showNotification()
        }
        )
      } else {
        showNotification()
      }
    })
  })
})