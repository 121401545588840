<template>
  <ul class="nav nav-underline">
    <li class="nav-item">
      <router-link class="nav-link" :class="{ active: $route.path === to }" :to="to"><slot></slot></router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavBarBootstrapMenuLink',
  props: ['to']
}
</script>
