<template>
  <img v-if="isSignedIn && hasProfilePhoto" :style="cssVars" class="profileImg" alt="" v-bind:src="this.user.photoURL">
  <img v-else-if="isSignedIn && hasNoProfilePhoto" :style="cssVars" class="profileImg" alt="" src="./assets/dummyProfileImage.png">
</template>

<script>

export default {
  name: 'ProfilePhoto',
  props: {
    imagewidth: {
      default: '100'
    },
    user: Object
  },
  computed: {
    isSignedIn: function () {
      try {
        return !!(this.user)
      } catch (error) {
        return false
      }
    },
    hasProfilePhoto: function () {
      try {
        return this.user.photoURL
      } catch (error) {
        return false
      }
    },
    hasNoProfilePhoto: function () {
      try {
        return this.user.photoURL == null
      } catch (error) {
        return true
      }
    },
    cssVars () {
      return {
        'max-width': this.imagewidth + 'px'
      }
    }
  }
}
</script>

<style scoped>
  .profileImg {
    border-radius: 50%;
  }
</style>
