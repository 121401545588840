import storeHelpers from '../helpers'

export const maps = {
  namespaced: true,
  state () {
    return {
      maps: {},
      mapsSubscription: null
    }
  },
  mutations: {
    setMapsById (state, data) {
      storeHelpers.setNameById(state, data, 'maps')
    },
    setMapsSubscription (state, data) {
      storeHelpers.setNameSubscription(state, data, 'maps')
    }
  },
  actions: {
    subscribeMaps ({ commit, state }) {
      storeHelpers.subscribeByName(commit, state, 'maps')
    },  
    unsubscribeMaps ({ commit, state }) {
      storeHelpers.unsubscribeByName(commit, state, 'maps')
    }
  },
  getters: {
  }
}
