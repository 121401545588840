import { createStore } from 'vuex'

import { live } from './modules/live'
import { images } from './modules/images'
import { runs } from './modules/runs'
import { maps } from './modules/maps'
import { sessions } from './modules/sessions'
import { runLists } from './modules/runLists'
import { reports } from './modules/reports'

const store = createStore({
  state: {
    user: null,
    userClaims: null,
    userAccessToken: null,
    users: {},
  },
  getters: {
  },
  mutations: {
    setUser (state, user) {
      state.user = user
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          state.userClaims = idTokenResult.claims
          state.userAccessToken = idTokenResult.token
        })
      } else {
        state.userClaims = null
        state.userAccessToken = null
      }
    },
    setUsers (state, data) {
      state.users = data
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
      if (user) {
        fetch(process.env.VUE_APP_API_URL + '/user_data', {
          headers: {
            authorization: 'Bearer ' + user.accessToken
          }
        })
          .then(response => response.json())
          .then(users => {
            commit('setUsers', users)
          })
      }
    }
  },
  modules: {
    live,
    sessions,
    runs,
    maps,
    runLists,
    images,
    reports
  }
})

export default store
