<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-white">
    <div class="row justify-content-between w-100 g-0 px-2 mx-1">
      <div class=" col-auto text-center order-2 order-lg-first">
        <a class="navbar-brand m-1" href="/">
          <img style="height: 40px;" id="logo" alt="Logo" src="./assets/logo-minimal.svg">
        </a>
      </div>
      <div class="col-5 col-lg-auto flex-lg-grow-1 order-1">
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarLeft" aria-controls="offcanvasNavbar">
          <span v-if="showMenuLeft" class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasNavbarLeft" aria-labelledby="offcanvasNavbarLabel">
          <div class="offcanvas-header">
            <div class="offcanvas-title" id="offcanvasNavbarLabel">
              <img style="height: 40px;" id="logo" alt="Logo" src="./assets/logo-minimal.svg">
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0" data-bs-dismiss="offcanvas">
              <slot name="menuLeft"></slot>
            </ul>
          </div>
        </div>
        <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div class="offcanvas-header">
            <div class="offcanvas-title" id="offcanvasNavbarLabel">
              <img style="height: 40px;" id="logo" alt="Logo" src="./assets/logo-minimal.svg">
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body aligned-with-border">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-2" data-bs-dismiss="offcanvas">
              <slot name="menu"></slot>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-5 col-lg-auto order-last">
        <div class="row justify-content-end g-2" style="flex-grow:1">
          <div class="col-auto">
            <slot name="profile"></slot>
          </div>
          <div class="col-auto">
            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" style="width: 2em; height: 2em;">
              <i class="bi bi-three-dots-vertical h2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBarBootstrap',
  props: ['showMenuLeft']
}
</script>

<style scoped>
  .navbar {
    border-bottom: thin solid #bebebe;
  }
  .offcanvas-title {
    width: 100%;
    text-align: center;
  }
  .btn-close {
    padding-right: 25px;
  }
  .navbar-toggler {
    border: 0px;
    padding: 1px;
  }
  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
  .navbar-toggler:focus {
    box-shadow:none;
  }
  .navbar-brand {
    margin-right: 0px;
  }
  .aligned-with-border {
    margin-top: 8px;
    margin-bottom: -8px;
  }
</style>
